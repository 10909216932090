<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class=" flex-column  bg-white overflow-hidden radius-20">
				<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line">
					<h4>입금 계좌 설정</h4>
					<v-icon
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>
				<div class="pa-20">
					<div class="mt-10 flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">은행</div>
						<div class="flex-2">
							<select
								v-model="item_withdrawal.bank_code"
								class="input-box "
							>
								<option value="">은행을 선택하세요</option>
								<option
									v-for="(item, b_index) in user.bank_list"
									:key="'bank_' + b_index"
									:value="item.bankId"
								>{{ item.bankName }}</option>
							</select>
						</div>
					</div>
					<div class="mt-10 flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">계좌번호</div>
						<div class="flex-2"><input v-model="item_withdrawal.bank_account" class="input-box " placeholder="계좌번호를 입력하세요" maxlength="50" type="number" :rules="$rules.max(item_withdrawal, 'bank_account', 50)" /></div>
					</div>
					<div class="mt-10 flex-row justify-space-between items-center">
						<div class="flex-1 font-weight-bold">예금주</div>
						<div class="flex-2"><input v-model="item_withdrawal.bank_holder" class="input-box " placeholder="예금주를 입력하세요" maxlength="50" /></div>
					</div>
				</div>

				<div
					class="mt-auto "
				>
					<button
						class="btn btn-primary pa-10"
						:disabled="disabled"

						@click="postDeposit"
					><slot name="name-confirm">확인</slot></button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'UserDepositWallet'
	, components: {PopupLayer}
	, props: ['user', 'codes']
	, data: function(){
		return {
			item_withdrawal: {
				bank_code: ''
				, bank_account: ''
				, bank_holder: ''
			}
		}
	}
	, computed: {
		disabled: function(){
			let t = true
			if(this.item_withdrawal.bank_code && this.item_withdrawal.bank_account && this.item_withdrawal.bank_holder){
				t = false
			}
			return t
		}
	}
	, methods: {
		postDeposit: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'user/postDeposit'
					, data: {
						bank_code: this.item_withdrawal.bank_code
						, bank_account: this.item_withdrawal.bank_account
						, bank_holder: this.item_withdrawal.bank_holder
					}
				})
				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
}
</script>